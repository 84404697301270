import Cookie from '~/js/modules/Cookie';

const footerPopupBtn = document.querySelector('.js-chat-btn');
const footerPopupBlock = document.querySelector('.js-chat-popup');
const chatContainer = document.querySelector('.chat-options');

function ChatPopup(popupBtn, popupElem) {
  this.windowWidth = document.documentElement.clientWidth;

  this._events = () => {
    window.addEventListener('resize', () => {
      this.windowWidth = document.documentElement.clientWidth;
      popupElem.classList.remove('show');
    });

    if(this.windowWidth <= 1024) {
      popupBtn.addEventListener('click', e => {
        e.preventDefault();
        if (!popupElem.classList.contains('show')) {
          popupElem.classList.add('show');
        }
      });
    } else {
      popupBtn.addEventListener('mouseover', e => {
        e.preventDefault();
        if (!popupElem.classList.contains('show')) {
          popupElem.classList.add('show');
        }
      });
    }

    chatContainer.addEventListener('mouseleave', () => {
      if(this.windowWidth >= 1024) {
        popupElem.classList.remove('show');
      }
    });

    popupElem.querySelector('.js-chat-close').addEventListener('click', () => {
      popupElem.classList.remove('show');
    });

    $(document).mouseup( (e) => {
      const container = $('.js-chat-popup, .js-chat-btn');

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        popupElem.classList.remove('show');
      }
    });

    document.addEventListener('touchend', (e) => {
      const container = $('.js-chat-popup, .js-chat-btn');

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        popupElem.classList.remove('show');
      }
    });
  };

  this._init = () => {
    this._events();
  };
}

if (footerPopupBtn) {
  const popup = new ChatPopup(footerPopupBtn, footerPopupBlock);

  popup._init();
}

// LIMITED OFFER POPUP

const pathnames = [
  "/weddingmoons/",
  "/weddings/",
  "/weddings/inspirations/",
  "/weddings/ideas/wishlist-catalog/",
  "/weddings/ideas/look-book/",
  "/weddings/inclusions/",
  "/weddings/venues/",
  "/weddings/guests/",
  "/weddings/planner/",
  "/weddings/media-packages/",
  "/weddings/menu/",
  "/weddings/entertainment/",
  "/weddings/faqs/",
  "/engagements/",
  "/proposals/",
  "/honeymoons/",
  "/anniversaries/",
  "/vow-renewals/",
  "/weddings/promotions/",
  "/celebrations/promotions/",
  "/weddings/ideas/"
];

const getLocation = window.location.pathname;

if (getLocation) {
  /*const limitedPopupBtn = document.getElementById('js-magnific-popup');

  function setNewsletterCookie() {
    const d = new Date();
  
    // 60sec*60mins*72hours = 259200 (3 day), 5*60*1000(5mins)
    d.setTime(d.getTime() + 259200000); // in milliseconds
    document.cookie = 'HIDE_LIMITED_POPUP=true;path=/;expires=' + d.toGMTString() + ';';
  }
  
  function timeOutPopup() {
    const hideLimitedPopup = Cookie.has('HIDE_LIMITED_POPUP') ? Cookie.get('HIDE_LIMITED_POPUP') : 'false';
  
    if (hideLimitedPopup === 'false') {
      limitedPopupBtn.click();
      setNewsletterCookie();
    }
  }
  
  if (limitedPopupBtn) {
    setTimeout(timeOutPopup, 20000);
  }
  
  $(document).mouseleave((e) => {
    if (e.clientY < 10 && limitedPopupBtn) {
      timeOutPopup();
    }
  }); */
}

function checkOffsetTop(target) {
  const isDesktop = window.innerWidth >= '768';

  const top = isDesktop ? target.offsetTop - 30 : target.offsetTop + 20;

  return top;
}

window.addEventListener('load', () => {
  const main = $('html, body');
  const urlScrollParam = window.location.search.split('scrollTo=')[1];

  if (!!urlScrollParam && urlScrollParam === 'cta-section') {
    const targetHasClass = document.querySelector(`.${urlScrollParam}`);
    const offsetTop = checkOffsetTop(targetHasClass);

    setTimeout(() => {
      main.animate({
        scrollTop: offsetTop
      }, 1000);
    }, 1500);
  }
});
